<template>
    <slot />
</template>

<script>
export default {
    name: 'Empty',
    inheritAttrs: false
};
</script>
