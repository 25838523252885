<template>
    <Component
        :is="teleport ? 'MagicPortal' : 'Empty'"
        :disabled="!isShowing"
        class="popup-portal"
        :to="target"
    >
        <Transition
            :name="transition || `menu-${_position}`"
            @enter="onEnter"
            @after-leave="onAfterLeave"
        >
            <AriaHidden
                v-if="keepAlive || isShowing || teleport"
                v-show="!keepAlive || isShowing || teleport"
                ref="popup"
                :class="[ `${type}-popup`, _position, _align, { translucent, dark, full, open: isShowing }, popupClass ]"
                :style="{ transform }"
                class="popup"
                :disabled="isShowing"
                v-bind="$attrs"
                @keydown="onKeydown"
            >
                <div
                    v-if="arrow && !transform"
                    class="arrow"
                    role="presentation"
                />
                <Component
                    :is="focusTrap ? 'FocusTrap' : 'div'"
                    ref="popupInner"
                    class="popup-inner"
                    :fullscreen="fullscreen"
                    :role="role"
                    @click="$emit('click', $event)"
                >
                    <slot
                        v-bind="{
                            divider: { class: 'popup-divider' },
                            unstyle: { class: 'popup-unstyle' },
                            inert: { class: 'popup-inert' }
                        }"
                    />
                </Component>
            </AriaHidden>
        </Transition>
    </Component>
</template>

<script>
import Empty from '@/components/utility/empty';
import FocusTrap from '@/components/utility/focus-trap';
import AriaHidden from '@/components/utility/aria-hidden';
import MagicPortal from '@/components/utility/magic-portal';

export default {
    name: 'Popup',
    components: { Empty, FocusTrap, AriaHidden, MagicPortal },
    props: {
        open: { type: Boolean, default: false },
        hover: { type: Boolean, default: false },

        type: { type: String, default: 'standalone', options: ['standalone', 'dropdown', 'tooltip'] },
        position: { type: String, default: 'bottom', options: ['top', 'bottom', 'left', 'right'] },
        align: { type: String, default: 'center', options: ['top', 'bottom', 'left', 'right', 'center'] },
        boundary: { type: HTMLElement, default: undefined },
        role: { type: String, default: 'dialog' },
        fullscreen: { type: Boolean, default: false },
        transition: { type: String, default: undefined },
        popupClass: { type: String, default: undefined },

        translucent: { type: Boolean, default: false },
        dark: { type: Boolean, default: false },
        full: { type: Boolean, default: false },
        arrow: { type: Boolean, default: true },
        teleport: { type: [Boolean, String], default: false },
        focusTrap: { type: Boolean, default: true },
        typeahead: { type: Boolean, default: true },
        keepAlive: { type: Boolean, default: false },
        alwaysOpen: { type: Boolean, default: false },
        preventOutsideClick: { type: Boolean, default: false }
    },
    emits: ['update:open', 'update:hover', 'toggle', 'open', 'close', 'click', 'hover'],
    slots: ['default'],
    data() {
        return {
            isOpen: false,
            isOpened: false,
            isHovering : false,
            preventEcho: false,

            searchString: '',
            searchTimer: null,

            transform: null,
            dynamicPosition: this.position || 'bottom',
            dynamicAlign: this.align
        };
    },
    computed: {
        isShowing() {
            if (this.alwaysOpen) return true;
            return this.isOpen || this.isHovering;
        },
        isKeyboardNavigable() {
            if (!this.typeahead) return false;
            return this.role === 'listbox'
                || this.role === 'menu';
        },
        _align() {
            let align = this.align;
            if (this.boundary) {
                if (
                    (this.dynamicAlign === 'top' || this.dynamicAlign === 'bottom')
                    && (this._position === 'left' || this._position === 'right')
                ) {
                    align = this.dynamicAlign;
                }
                if (
                    (this.dynamicAlign === 'left' || this.dynamicAlign === 'right')
                    && (this._position === 'top' || this._position === 'bottom')
                ) {
                    align = this.dynamicAlign;
                }
            }
            return `align-${align}`;
        },
        _position() {
            let position = this.position;
            if (this.boundary) {
                if (
                    (this.dynamicPosition === 'top' || this.dynamicPosition === 'bottom')
                    && (this.position === 'top' || this.position === 'bottom')
                ) {
                    position = this.dynamicPosition;
                }
                if (
                    (this.dynamicPosition === 'left' || this.dynamicPosition === 'right')
                    && (this.position === 'left' || this.position === 'right')
                ) {
                    position = this.dynamicPosition;
                }
            }
            return position;
        },
        target() {
            if (this.teleport === true) return 'body';
            return this.teleport;
        }
    },
    watch: {
        open: {
            handler(to) {
                this.isOpen = to;
                this.preventEcho = true;
                this.$timeout(() => this.isOpened = to, 350);
                if (this.type === 'standalone') document[to ? 'addEventListener' : 'removeEventListener']('click', this.onOutsideClick);
            },
            immediate: true
        },
        hover(to) {
            this.isHovering = to;
            this.preventEcho = true;
        },
        isOpen(to) {
            this.$emit('toggle', to);
            this.$emit('update:open', to);
            if (to) {
                this.$emit('open');
                if (this.teleport) this.$nextTick(() => this.$nextTick(() => this.$nextTick(() => this.onEnter())));
            }

        },
        isHovering(to) {
            this.$emit('hover', to);
            if (this.preventEcho) return this.preventEcho = false;
            this.$emit('update:hover', to);
        }
    },
    mounted() {
        if (this.alwaysOpen) this.onEnter();
    },
    methods: {
        onOutsideClick(event) {
            if (!this.isOpened) return;
            if (this.preventOutsideClick) return;
            if (!this.$el.contains(event.target)) this.close();
        },
        onEnter() {
            if (!this.boundary) return;
            this.$nextTick(() => {
                const boundary = this.boundary.getBoundingClientRect();
                if (!(this.$refs.popupInner && this.$refs.popupInner.$el && this.$refs.popupInner.$el.getBoundingClientRect)) return;
                const { left, right, top, bottom } = this.$refs.popupInner.$el.getBoundingClientRect();
                if (left < boundary.left) {
                    this.dynamicPosition = 'right';
                    this.dynamicAlign = 'left';
                }
                if (right > boundary.right) {
                    this.dynamicPosition = 'left';
                    this.dynamicAlign = 'right';
                }
                if (top < boundary.top) {
                    this.dynamicPosition = 'bottom';
                    this.dynamicAlign = 'top';
                }
                if (bottom > boundary.bottom) {
                    this.dynamicPosition = 'top';
                    this.dynamicAlign = 'bottom';
                }
            });
        },
        onAfterLeave() {
            this.$emit('close');
        },
        onKeydown(event) {
            const { key, altKey, ctrlKey, metaKey } = event;
            if (key === 'Escape') {
                this.close();
                event.stopPropagation();
                event.preventDefault();
            }
            if (!this.isKeyboardNavigable) return;

            const isTyping = key.length === 1 && key !== ' ' && !altKey && !ctrlKey && !metaKey;
            if (key === 'ArrowUp') {
                this.focusPrevious();
                event.preventDefault();
            } else if (key === 'ArrowDown') {
                this.focusNext();
                event.preventDefault();
            } else if (key === 'Tab') {
                this.close();
            } else if (isTyping) {
                const search = this.onTypeahead(key);
                const element = this.getOptionBySearch(search);
                if (element) element.focus();
            }
        },
        onTypeahead(char) {
            this.searchTimer = this.$debounce(() => {
                this.searchString = '';
                this.searchTimer = null;
            }, 500, this.searchTimer);

            this.searchString += char;
            return this.searchString;
        },
        getElements() {
            if (!this.$refs.popupInner) return;
            return [ ...this.$refs.popupInner.$el.querySelectorAll('button:not(disabled), [href]') ];
        },
        getOptionBySearch(search) {
            const elements = this.getElements();
            const currentIndex = elements.findIndex(item => item === document.activeElement);
            const orderedElements = [
                ...elements.slice(currentIndex),
                ...elements.slice(0, currentIndex),
            ];

            const firstMatch = this.searchOptions(orderedElements, search);
            const repeatedLetter = array => array.every(letter => letter === array[0]);

            if (firstMatch) { // First check if there is an exact match for the typed string
                return firstMatch;
            } else if (repeatedLetter(search.split(''))) { // If the same letter is being repeated, cycle through first-letter matches
                return this.searchOptions(orderedElements, search[0]);
            } else {
                return null;
            }
        },
        searchOptions(options = [], search) {
            const index = options.findIndex(option => {
                const label = option.getAttribute('aria-label');
                const text = option.innerText;
                return (text && text.toLowerCase().indexOf(search.toLowerCase()) === 0)
                    || (label && label.toLowerCase().indexOf(search.toLowerCase()) === 0);
            });
            return options[index];
        },
        focusNext() {
            const elements = this.getElements();
            const index = elements.findIndex(item => item === document.activeElement);
            if (!~index) return;
            const next = elements[index + 1] || elements[0];
            next.focus();
        },
        focusPrevious() {
            const elements = this.getElements();
            const index = elements.findIndex(item => item === document.activeElement);
            if (!~index) return;
            const previous = elements[index - 1] || elements[elements.length - 1];
            previous.focus();
        },
        close() {
            this.isOpen = false;
        }
    }
};
</script>

<style lang="less" scoped>

.popup {
    --popup-background: #FFF;
    --popup-text: var(--coal);

    position: absolute;
    display: flex;
    z-index: 3;

    .arrow {
        z-index: 1;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent;
    }
    &.top {
        flex-direction: column-reverse;
        bottom: 100%;
        left: 0;
        right: 0;
        margin-bottom: 8px;
        > .arrow {
            margin: 0 12px;
            border-width: 6px 6px 0 6px;
            border-color: var(--popup-background) transparent transparent transparent;
        }
        &.align-left { align-items: flex-start; }
        &.align-center { align-items: center; }
        &.align-right { align-items: flex-end; }
    }
    &.bottom, &.above {
        flex-direction: column;
        left: 0;
        right: 0;
        > .arrow {
            margin: 0 12px;
            border-width: 0 6px 6px 6px;
            border-color: transparent transparent var(--popup-background) transparent;
        }
        &.align-left {
            right: unset;
            align-items: flex-start;
        }
        &.align-right {
            left: unset;
            align-items: flex-end;
        }
        &.align-center { align-items: center; }
    }
    &.above {
        top: 0;
    }
    &.bottom {
        top: 100%;
        margin-top: 8px;
    }
    &.right {
        flex-direction: row;
        left: 100%;
        top: 0;
        bottom: 0;
        margin-left: 8px;
        > .arrow {
            margin: 12px 0;
            border-width: 6px 6px 6px 0;
            border-color: transparent var(--popup-background) transparent transparent;
        }
        &.align-top { align-items: flex-start; }
        &.align-center { align-items: center; }
        &.align-bottom { align-items: flex-end; }
    }
    &.left {
        flex-direction: row-reverse;
        right: 100%;
        top: 0;
        bottom: 0;
        margin-right: 8px;
        > .arrow {
            margin: 12px 0;
            border-width: 6px 0 6px 6px;
            border-color: transparent transparent transparent var(--popup-background);
        }
        &.align-top { align-items: flex-start; }
        &.align-center { align-items: center; }
        &.align-bottom { align-items: flex-end; }
    }
    .popup-inner {
        display: flex;
        flex: 1 1 auto;
        cursor: default;
        text-align: left;
        background-color: var(--popup-background);
        color: var(--popup-text);
    }
    &.full {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        > .popup-inner {
            width: 100%;
            max-width: 100%;
            min-width: 100%;
        }
    }
    &.dark {
        > .popup-inner, > .arrow {
            --popup-background: var(--night);
            --popup-text: #FFF;
        }
    }
    &.translucent {
        > .popup-inner, > .arrow {
            --popup-background: ~"rgba(var(--coal-rgb), 0.6)";
            --popup-text: #FFF;
        }
    }
}
</style>

<style lang="less">

.portal-wrapper.popup-portal {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    .popup {
        opacity: 0;
        &.open {
            opacity: 1;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0;
            pointer-events: all;
        }
    }
}
.popup {
    .popup-unstyle, .popup-inert {
        display: inherit;
        padding: 0;
        &:hover {
            background: none;
        }
    }
    .popup-divider, .popup-inert {
        pointer-events: none;
        cursor: default;
    }
    &.dropdown-popup hr.popup-divider {
        margin: 4px 0;
        min-height: 1px;
        height: 1px;
        border: 0;
        background-color: var(--smoke);
    }
    &.standalone-popup .popup-inner {
        padding: 8px;
        border-radius: 16px;
    }
    &.dark {
        .popup-divider {
            background-color: rgba(255, 255, 255, 0.12);
        }
    }
}
</style>
