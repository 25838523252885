<template>
    <Component
        :is="tag"
        :aria-hidden="!disabled"
    >
        <slot v-bind="{ tabindex }" />
    </Component>
</template>

<script>
// This component should be used when regions of content must be hidden from a screen reader/tabbing context.
// Adding 'aria-hidden' alone will not prevent focusing child elements, each needs to have their tabindex
// set to '-1'. Focusable components can implement the provided 'tabindex' property (see button.vue). Manually
// binding to any focusable children through the use of v-slot may be necessary if used in the same component.

// Composition API is used here due to reactivity issues in Vue 3.2 (To be fixed in 3.3)

import { computed, provide } from 'vue';

export default {
    name: 'AriaHidden',
    props: {
        tag: { type: String, default: 'span' },
        disabled: { type: Boolean, default: false }
    },
    setup(props) {
        const tabindex = computed(() => props.disabled ? null : '-1');

        provide('tabindex', tabindex);

        return {
            tabindex
        };
    }
};
</script>
