<template>
    <Teleport
        v-if="isMounted"
        :to="to"
        :disabled="disabled"
    >
        <span
            ref="wrapper"
            class="portal-wrapper"
            :class="{ enabled: !disabled }"
            :style="{
                zIndex,
                transform,
                width: !disabled ? `${width}px` : null,
                height: !disabled ? `${height}px` : null
            }"
            v-bind="$attrs"
        >
            <slot />
        </span>
    </Teleport>
</template>

<script>
export default {
    name: 'MagicPortal',
    inheritAttrs: false,
    props: {
        to: { type: String, default: 'body' },
        disabled: { type: Boolean, default: false },
        zIndex: { type: Number, default: 99999 }
    },
    emits: ['portal'],
    slots: ['default'],
    data() {
        return {
            target: null,
            offsetX: 0,
            offsetY: 0,
            scrollX: 0,
            scrollY: 0,
            width: 0,
            height: 0,

            isMounted: false
        };
    },
    computed: {
        transform() {
            if (!(this.offsetX || this.offsetY)) return;
            return `translate(${this.offsetX + this.scrollX}px, ${this.offsetY + this.scrollY}px)`;
        }
    },
    watch: {
        disabled(to) {
            if (to) {
                this.offsetX = 0;
                this.offsetY = 0;
                this.scrollX = 0;
                this.scrollY = 0;
                this.width = 0;
                this.height = 0;
            } else {
                this.setup();
            }
        }
    },
    mounted() {
        this.isMounted = true;
        if (!this.disabled) this.$nextTick(() => this.setup());
    },
    methods: {
        setup() {
            this.target = document.querySelector(this.to);
            let wrapper = this.$refs.wrapper;
            if (!wrapper) return;
            if (wrapper.firstElementChild) wrapper = wrapper.firstElementChild;
            const { left: originalX, top: originalY, width, height } = wrapper.getBoundingClientRect();
            this.$nextTick(() => this.$nextTick(() => {
                const { left: x, top: y } = this.target.getBoundingClientRect();
                this.offsetX = originalX - x;
                this.offsetY = originalY - y;
                this.scrollX = this.target.scrollLeft;
                this.scrollY = this.target.scrollTop;
                this.width = width;
                this.height = height;
            }));
        }
    }
};
</script>

<style lang="less" scoped>
.portal-wrapper {
    &.enabled {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 9999;
    }
}
</style>
